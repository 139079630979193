import { useEffect, useMemo, useState } from "react";
import styles from "./Reservation.scss";
import classNames from "classnames";
import axios from "axios";
import { imageURL } from "../libs/image";
const cx = classNames.bind(styles);

const TODAY = new Date();
const WEEK_DAYS = ["일", "월", "화", "수", "목", "금", "토"];

const NOT_ALLOWED_DAYS = [0];

const MIN_TIME = "11:00";
const NOT_ALLOWED_TIMES = [
  { start: "12:00", end: "12:59" },
  { start: "18:00", end: "18:59" },
];
const MAX_TIME = "20:00";
const TIME_GAP = "01:00";

const BASE_URL =
  "https://script.google.com/macros/s/AKfycbynhl4zVhIXEedBje2Z8BFByvSWsVx6Udq1ePpaRmkgJZZg4ND78AZXGHfyPC-baPqW/exec";

const Reservation = () => {
  const defaultDate = useMemo(() => {
    let timeSet = new Date().getHours() + 1;
    let isNextday = false;
    if (timeSet > Number(MAX_TIME.split(":")[0])) {
      timeSet = MIN_TIME.split(":")[0];
      isNextday = true;
    }
    if (!isNextday) {
      return new Date(
        new Date(
          new Date(new Date().setHours(timeSet)).setMinutes(0)
        ).setMilliseconds(0)
      );
    }
    return new Date(
      new Date(
        new Date(
          new Date(new Date().setHours(timeSet)).setMinutes(0)
        ).setMilliseconds(0)
      ).setDate(TODAY.getDate() + 1)
    );
  }, []);

  const [selectDate, setSelectDate] = useState(defaultDate);

  useEffect(() => {
    if (selectDate.getTime() < TODAY.getTime()) {
      setSelectDate(defaultDate);
    }
  }, [selectDate, defaultDate]);

  const thisMonthFirstDay = useMemo(
    () =>
      new Date(
        new Date(
          new Date(selectDate).setMonth(new Date(selectDate).getMonth())
        ).setDate(1)
      ).getDay(),
    [selectDate]
  );

  const times = useMemo(() => {
    const [minTime, minMinute] = MIN_TIME.split(":");
    const [maxTime, maxMinute] = MAX_TIME.split(":");

    let calculate = `${minTime}:${minMinute}`;
    const timeList = [];
    timeList.push(MIN_TIME);
    while (
      Number(calculate.split(":")[0]) < Number(maxTime) ||
      Number(calculate.split(":")[1]) < Number(maxMinute)
    ) {
      let [calTime, calMinute] = calculate.split(":");
      const [gapTime, gapMinute] = TIME_GAP.split(":");
      calTime = Number(calTime) + Number(gapTime);
      calMinute = Number(calMinute) + Number(gapMinute);

      if (Number(calMinute) >= 60) {
        calMinute = Number(calMinute) - 60;
        calTime = Number(calTime) + 1;
      }

      const notAllowed = NOT_ALLOWED_TIMES.some(({ start, end }) => {
        const [startTime, startMinute] = start.split(":");
        const [endTime, endMinute] = end.split(":");

        if (calTime === Number(startTime) && calMinute >= Number(startMinute)) {
          return true;
        }
        if (calTime === Number(endTime) && calMinute <= Number(endMinute)) {
          return true;
        }
        if (calTime > Number(startTime) && calTime < Number(endTime)) {
          return true;
        }
        return false;
      });

      while (String(calTime).length < 2) {
        calTime = `0${calTime}`;
      }

      while (String(calMinute).length < 2) {
        calMinute = `0${calMinute}`;
      }

      calculate = `${calTime}:${calMinute}`;
      if (notAllowed) {
        continue;
      }
      if (calTime >= Number(maxTime) && calMinute >= Number(maxMinute)) {
        break;
      }
      timeList.push(calculate);
    }
    return timeList;
  }, []);

  const [error, setError] = useState({});
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [memo, setMemo] = useState("");
  const [isAllowed, setIsAllowed] = useState(false);
  const [visitType, setVisitType] = useState("외부 방문객");

  const handleChange = (setState) => (e) => {
    setState(e.target.value);
  };

  const handleChecked = (setState) => (e) => {
    setState(e.target.checked);
  };

  const handleRegist = async () => {
    if (!isAllowed) {
      setError({ isAllowed: "예약을 위해 개인정보 수집에 동의해주세요." });
      return;
    }

    if (selectDate.getTime() < defaultDate.getTime()) {
    }
    setError({});

    const resposne = await axios.post(
      BASE_URL,
      JSON.stringify({
        name,
        phoneNumber,
        address,
        date: selectDate,
        memo,
        visitType,
      })
    );

    if (resposne.data.ok) {
      window.alert("예약 신청이 완료 되었습니다. 이전 페이지로 돌아갑니다.");
      window.history.back();
    }
  };

  const handleChangeMonth = (month) => () => {
    setSelectDate(
      (last) =>
        new Date(new Date(last).setMonth(new Date(last).getMonth() + month))
    );
  };

  const handleChangeDate = (date, month, year) => () => {
    setSelectDate(
      (last) =>
        new Date(new Date(new Date(year, month, date)).setMilliseconds(0))
    );
  };

  const handleChangeTime = (hour, minute) => () => {
    setSelectDate(
      (last) =>
        new Date(
          new Date(
            new Date(new Date(last).setHours(hour)).setMinutes(minute)
          ).setMilliseconds(0)
        )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleRegist();
  };

  const disablePrevMonth =
    new Date(selectDate).setDate(0) <= new Date(TODAY).setDate(1);
  const disableNextMonth =
    new Date(selectDate).setMonth(new Date(selectDate).getMonth() + 1) >=
    new Date(
      new Date(defaultDate).setMonth(defaultDate.getMonth() + 3)
    ).setHours(0);

  return (
    <>
      <main className={cx("reservation")}>
        <img
          src={imageURL + "/hongbo-main-bg-2.jpg"}
          className={cx("background-image")}
          alt=""
        />
        <form
          className={cx("form-container")}
          style={{ backdropFilter: "blur(10px)" }}
          onSubmit={handleSubmit}
        >
          <h2 className={cx("title")}>홍보관 예약 신청</h2>
          <legend className={cx("form-inputs-container")}>
            <p>방문 유형</p>
            <select value={visitType} onChange={handleChange(setVisitType)}>
              <option value="외부 방문객">외부 방문객</option>
              <option value="내부 성도">내부 성도</option>
            </select>
            <p>이름</p>
            <input
              value={name}
              onChange={handleChange(setName)}
              className="text-black"
            ></input>
            <p>주소(구)</p>
            <input
              value={address}
              onChange={handleChange(setAddress)}
              className="text-black"
            ></input>
            <p>연락처</p>
            <input
              value={phoneNumber}
              onChange={handleChange(setPhoneNumber)}
              className="text-black"
            ></input>
            <p>예약일</p>
            <article className={cx("calendar")}>
              <div className={cx("months")}>
                <button
                  onClick={handleChangeMonth(-1)}
                  disabled={disablePrevMonth}
                  type="button"
                  className={disablePrevMonth ? cx("disable") : ""}
                >
                  이전 달
                </button>
                <span className={cx("title")}>
                  {new Date(selectDate).getMonth() + 1}월
                </span>
                <button
                  onClick={handleChangeMonth(+1)}
                  disabled={disableNextMonth}
                  type="button"
                  className={disableNextMonth ? cx("disable") : ""}
                >
                  다음 달
                </button>
              </div>
              <div className={cx("dates")}>
                {WEEK_DAYS.map((day) => (
                  <p key={day} className={cx("day")}>
                    {day}
                  </p>
                ))}
                {Array.from({ length: 42 }, (_, index) => {
                  const date = new Date(
                    new Date(
                      new Date(
                        new Date().setFullYear(selectDate.getFullYear())
                      ).setMonth(selectDate.getMonth())
                    ).setDate(index - thisMonthFirstDay + 1)
                  );
                  const isThisMonth = date.getMonth() === selectDate.getMonth();
                  const isSelect =
                    isThisMonth && date.getDate() === selectDate.getDate();
                  const isDisabled =
                    date.getTime() < defaultDate.getTime() ||
                    date.getTime() >
                      new Date(defaultDate).setMonth(
                        defaultDate.getMonth() + 3
                      ) ||
                    NOT_ALLOWED_DAYS.some((days) =>
                      String(days).includes(String(date.getDay()))
                    );
                  return (
                    <button
                      key={date.getTime()}
                      disabled={isDisabled}
                      onClick={handleChangeDate(
                        date.getDate(),
                        date.getMonth(),
                        date.getFullYear()
                      )}
                      type="button"
                      className={`${cx("date")} ${
                        isDisabled
                          ? cx("disable")
                          : isSelect
                          ? cx("select")
                          : isThisMonth
                          ? cx("thisMonth")
                          : ""
                      }`}
                    >
                      {date.getDate()}
                    </button>
                  );
                })}
              </div>
              <div className={cx("times")}>
                {times.map((time) => {
                  const [hour, minute] = time.split(":");
                  const [selectHour, selectMinute] = [
                    selectDate.getHours(),
                    selectDate.getMinutes(),
                  ];
                  const isSelected =
                    Number(hour) === selectHour &&
                    Number(minute) === selectMinute;

                  const isDisabled =
                    new Date(new Date(selectDate).setHours(hour)).setMinutes(
                      minute
                    ) < defaultDate.getTime();
                  return (
                    <button
                      key={time}
                      type="button"
                      onClick={handleChangeTime(Number(hour), Number(minute))}
                      className={`${cx("time")} ${
                        isDisabled
                          ? cx("disable")
                          : isSelected
                          ? cx("select")
                          : ""
                      }`}
                    >
                      {time}
                    </button>
                  );
                })}
              </div>
            </article>
            <p>문의사항</p>
            <textarea value={memo} onChange={handleChange(setMemo)}></textarea>
          </legend>
          <legend className={cx("form-check-container")}>
            <label className={cx("check-label")}>
              <p>개인정보 수집/이용 및 제 3자 제공에 동의</p>
              <input
                checked={isAllowed}
                onChange={handleChecked(setIsAllowed)}
                type="checkbox"
              />
            </label>
            {error?.isAllowed ? (
              <p className={cx("errorMessage")}>{error.isAllowed}</p>
            ) : (
              <></>
            )}
            <button className={cx("submit-button")}>홍보관 예약하기</button>
          </legend>
        </form>
      </main>
    </>
  );
};

export default Reservation;
